/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/TransparentLC/transparentlc.github.io@master/js/mdui.min.js
 * - /npm/medium-zoom@1.1.0/dist/medium-zoom.min.js
 * - /npm/instant.page@5.2.0/instantpage.min.js
 * - /npm/aplayer@1.10.1/dist/APlayer.min.js
 * - /gh/TransparentLC/transparentlc.github.io@879beec/js/script.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
